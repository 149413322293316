import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {  useNavigate } from 'react-router-dom';


import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// import Container from 'components/atoms/Container';

import BackGroundImage from 'images/funfunBackground1.png';

import { getTests } from 'slices/FunFunSlice';

import CardListWrapper from 'components/CardListWrapper';

const Container = styled.div`
    width: 1200px;
    margin: 0 auto;
    margin-top: -45px;
    margin-bottom: 35px;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center top;
    @media (max-width: 768px) {
        width: 100%;
        background-size: 150%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 90%;
    }
`;

const FunFunTextContainer = styled.div`
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 150px;
    margin-bottom: 100px;
    @media screen and (max-width: 767px) {
        width: 90%;
        margin: 0 auto;
        padding-top: 100px;
        margin-bottom: 100px;
    }
`;

const JonathanTitle = styled.p`
    font-size: 14px;
    font-family: spoqaM;
    color: var(--blue105);
    border: 1px solid var(--blue105);
    border-radius: 12px;
    background-color: var(--blue101);
    width: 144px;
    padding: 7px 0;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 12px;
        width: 126px;
    }
`;

const FunFunTitle = styled.p`
    font-size: 36px;
    font-family: spoqaB;
    @media (max-width: 768px) {
        font-size: 28px;
    }
`;

const FunFunDesc = styled.p`
    font-size: 16px;
    font-family: spoqaM;
    @media (max-width: 768px) {
        width: 300px;
        line-height: 18px;
        font-size: 12px;
        word-break: keep-all;
        text-align: center;
        margin-bottom: -20px;
    }
`;

const FunFun = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { result } = useSelector(state => state.funFun);

    useEffect(() => {
        dispatch(getTests({ url:`https://flightbase.acryl.ai/deployment/service-api` }));
    }, []);

    const onClickCard = (id) => {
        navigate(`/service_detail/${id}`,{state: {id}});
    }

    return (
        <>
            <Container style={{backgroundImage: `url(${BackGroundImage})`}}>
                <FunFunTextContainer>
                    <JonathanTitle>Jonathan Fun Fun</JonathanTitle>
                    <FunFunTitle>조나단의 AI 체험하기</FunFunTitle>
                    <FunFunDesc>Jonathan Flightbase 의 빌트인 모델을 사용하여 만든 인공지능 서비스입니다.</FunFunDesc>
                </FunFunTextContainer>
                <CardListWrapper result={result} onClickCard={onClickCard}/>
            </Container>
        </>
    );
    
};

export default FunFun;