import React from 'react';
import styled from 'styled-components';

import StickGraph from './StickGraph';

const GraphWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    @media (max-width: 768px) {
        margin-top: 10px;
        width: 90%; 
    }
    p {
        width: 70px;
        @media (max-width: 768px) {
            font-size: 16px;
            width: 50px;
        }
    }
`;

const ResultGraph = ({ResultList, ResultType}) => {

    const MakeGraph = () => {
            if(ResultList === undefined) {
                return
            }

            return ResultList?.length > 1 && ResultList?.map((data) =>
                <GraphWrapper key={data[0]}>
                    <p style={{textAlign: 'right'}}>{data?.[0]}</p>
                    <StickGraph Percent={Math.floor(data?.[1]*100)} />
                    <p style={{textAlign: 'left'}}>{Math.floor(data?.[1]*100)}{ResultType}</p>
                </GraphWrapper> )
    }

    return (
        <>
            <MakeGraph />
        </>
    );
};

export default ResultGraph;