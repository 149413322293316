import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getTests } from 'slices/FunFunSlice';

const useGetURL = ({currentAPI}) => {

    const { result, itemResult } = useSelector(state => state.funFun);
    const [testPostAPI, setTestPostAPI] = useState('');
    const [pageUrl, setPageUrl] = useState('');

    const dispatch = useDispatch();
    const location = useLocation();

    // GET 요청을 통해 POST 요청을 전송할 api 주소를 받아옴
    useEffect(() => {
        setTestPostAPI(currentAPI);
    }, [itemResult]);

    // GET 요청을 통해 Page URL 을 받아옴
    useEffect(() => {
        let testPageUrl = 'https://funfun.acryl.ai' + location.pathname;
        setPageUrl(testPageUrl);
    }, []);

    return {
        testPostAPI,
        pageUrl
    };
};

export default useGetURL;