import React, { useState } from 'react';
import styled from 'styled-components';

import ModalIcon from 'images/modalIcon.png'; 

const Title = styled.h1`
    font-size: 30px;
    font-family: spoqaB;
    margin: 80px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const BuiltInModelWarpper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    white-space: no-wrap;
    p {
        font-family: spoqaR;
        font-size: 14px;
        line-height: 20px;
    }
    @media (max-width: 768px) {
        white-space: pre-line;
        p {
            font-size: 12px;
            text-align: center;
        }
    }
`;

const BuiltInModel = styled.button`
    font-family: spoqaB;
    font-size: 14px;
    border: none;
    background-color: var(--blue100);
    color: var(--blue105);
    cursor: pointer;
    display : flex;
    align-items: center;
    img {
        width: 18px;
        @media (max-width: 768px) {
            width: 14px;
        }
    }
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const BuiltInModelModal = styled.div`
    width: 500px;
    height: 68px;
    background-color: var(--blue101);
    border-radius: 20px;
    position: absolute;
    margin-top: 110px;
    display : flex;
    align-items: center;
    padding: 0 20px;
    p {
        font-family: spoqaR;
    }
    @media (max-width: 768px) {
        width: 280px;
    }
`;

const PageTitle = ({testTitle, modelName, pageName, modelDesc}) => {

    const [modelModal, setModelModal] = useState(false);

    const ModelButtonMouseOver = (e) => {
        setModelModal(true);
    }

    const ModelButtonMouseOut = (e) => {
        setModelModal(false);
    }

    return (
        <div>
            <Title>{testTitle}</Title>
            <BuiltInModelWarpper>
                <div style={{lineHeight: '10px'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p style={{textAlign: 'center'}}>빌트인 모델</p>
                        <BuiltInModel onMouseOver={ModelButtonMouseOver} onMouseOut={ModelButtonMouseOut}>{modelName}<img src={ModalIcon}/></BuiltInModel>
                        <p>을 사용하여</p>
                    </div>    
                    <p style={{marginRight: '4px', textAlign: 'center'}}>인공지능이 예측한 {pageName} 알아볼까요?</p> 
                </div>
                {modelModal &&
                    (
                        <BuiltInModelModal>
                            <p style={{wordBreak: 'keep-all'}}>
                                {modelDesc}                                
                            </p>
                        </BuiltInModelModal>
                    )
                }
            </BuiltInModelWarpper>        
                    
                    

            


        </div>
    );
};

export default PageTitle;