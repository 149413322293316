import React from 'react';
import styled from 'styled-components';

import Image from 'images/numberOfHeads.png';

const CardBorder = styled.div`
    border-radius: 30px;
    background-color: var(--white);
    width: 354px;
    height: 322px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    cursor: pointer;
    box-shadow: 0 12px 24px rgba(45, 118, 248, 0.1);
    :hover {
        box-shadow: 0 12px 24px rgba(45, 118, 248, 0.3);
        @media (max-width: 768px) {
            box-shadow: 0 12px 24px rgba(45, 118, 248, 0.1);
        }
    }
    img {
        width: 250px;
    }
    @media (max-width: 768px) {
        width: 200px;
        height: 170px;
        margin-bottom: 20px;
        position: relative;
        left: calc(50% - 100px);
        img {
            width: 130px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 220px;
        height: 188px;
        margin-bottom: 20px;
        img {
            width: 150px;
        }
    }
`;

const Title = styled.p`
    font-size: 20px;
    font-weight: bold;
    word-break: break-word;
    width: 300px;
    display: flex;
    justify-content: center;
    text-align: center;
    word-break: keep-all;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        font-size: 14px;
        width: 220px;
        margin-bottom: 20px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 16px;
        width: 220px;
        margin-bottom: 20px;
    }
`;

const Card = ({testTitle, onClick, id}) => {
    
    const ThumbnailURL = 'https://flightbase.acryl.ai/deployment/service-api/service_image/' + id + '/';

    return (
        <div onClick={onClick}>
            <CardBorder >
                <img src={ThumbnailURL}/>
                <Title>{testTitle}</Title>
            </CardBorder>   
        </div>
    );
};

export default Card;