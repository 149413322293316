import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getURL = createAsyncThunk("GET_BUILTINMODELS", async (payload, { rejectWithValue }) => {
    let result = null;

    try {
        result = await axios.get(`https://flightbase.acryl.ai/deployment/service-api/tutorial`);
    } catch (err) {
        result = rejectWithValue(err.response);
    }
    return result;
});

export const builtInModelSlice = createSlice({
    name: 'builtInModel',

    initialState: {
        loading: false,
        result: null
    },

    extraReducers: {
        [getURL.pending]: (state) => {
            return {
                ...state,
                loading: true
            }
        },

        [getURL.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                result: payload.data.result
            }
        },

        [getURL.rejected]: (state) => {
            return {
                ...state,
                loading: false
            }
        }
    }
});

export default builtInModelSlice.reducer;