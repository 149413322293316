import React from 'react';
import styled from 'styled-components';

import MenuList from './MenuList';

const NavBG = styled.div`
    background-color: var(--white);
    height: 60px;
    width: 100%
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        display: none;
    }
`;

const NavBarContainer = styled.ul`
    width: 1300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 100%;
        flex-direction: column;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

const NavBar = () => {

    return (
        <NavBG>
            <NavBarContainer>
                <MenuList />
            </NavBarContainer>
        </NavBG>
    );
};

export default NavBar;