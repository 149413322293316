import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from 'components/atoms/Card';

import { getTests } from 'slices/FunFunSlice';
import { reset } from 'slices/ImageUploadSlice';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import CardListWrapper from 'components/CardListWrapper';

const ContentsListInfo = styled.p`
    width: 477px;
    padding: 20px 0;
    background-color: var(--blue101);
    border-radius: 20px;
    margin: 50px 0 30px 0;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: var(--blue105);
    word-break: keep-all;
    @media (max-width: 768px) {
        width: 78%;
        font-size: 14px;
        margin: 30px 0 10px 0;
    }
`;

const FunfunContentsList = ({result, setImageSrc, setImagesSrc}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickCard = (id) => {
        navigate(`/service_detail/${id}`,{state: {id}});
        dispatch(reset())
        window.scrollTo({top: 0})
        setImageSrc ? setImageSrc('') : setImagesSrc([null,null])
    }

    return (
        <>  
            <div style={{borderTop: '2px solid var(--primary)', width: '80%', marginTop: '50px'}}></div>
            <ContentsListInfo>뻔뻔한 조나단의 다른 테스트도 해보세요 :-)</ContentsListInfo>
            <CardListWrapper result={result} onClickCard={onClickCard}/>
        </>
    );
};

export default FunfunContentsList;