import React from 'react';
import styled from 'styled-components';

import WrongIcon from 'images/wrong.png';

const MessageBox = styled.div`
    border: 2px dashed var(--red);
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(250, 78, 87, 0.08);
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0 20px 0;
    background-color: var(--white);
    img {
        width: 70px;
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        width: 280px;
        height: 180px;
        img {
            width: 50px;
            margin-bottom: 10px;
        }
    }
`;

const ReloadMessage = styled.p`
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    color: var(--red);
    @media (max-width: 768px) {
        width: 200px;
        height: 20px;
        font-size: 14px;
        word-break: keep-all;
        margin: 0;
    }
`;

const NeedToReload = ({fileType}) => {

    return (
        <>
        <MessageBox>
            <img src={WrongIcon} />
            <ReloadMessage>
                다른 {fileType}을 선택 해주세요 :(
            </ReloadMessage>
            <ReloadMessage>
                AI가 분석할 수 없는 {fileType}입니다.
            </ReloadMessage>
        </MessageBox>
        </>
    );
};

export default NeedToReload;