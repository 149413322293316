import styled from 'styled-components';

const Container = styled.div`
    margin: 0 auto;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1200px;
    @media (max-width: 768px) {
        width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 100%;
    }
`;

export default Container;