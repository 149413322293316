import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    background-color: var(--primary);
    border: none;
    color: var(--white);
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    font-family: SpoqaR;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`;


export default Button;