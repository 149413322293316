import React from 'react';
import styled from 'styled-components';

import ResultGraph from './ResultGraph';

const ResultBox = styled.div`
    background-color: var(--white);
    width: 50%;
    border-radius: 30px;
    box-shadow: 0 12px 24px rgba(45, 118, 248, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    margin: 15px 0 30px 0;
    font-weight: bold;
    font-size: 22px;
    white-space: pre-wrap;
    line-height: 30px;
    @media (max-width: 768px) {
        width: 60%;
        font-size: 16px;
        margin: 20px 0;
    }
`;

const QuestionText = styled.p`
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 10px 0 30px 0;
    @media (max-width: 768px) {
        font-size: 16px;
        margin: 40px 0 20px 0;
    }
`;

const FontRegular = styled.p`
    font-size: 18px;
    font-family: SpoqaR;
    word-wrap: keep-all;
    margin-bottom: 5px;
    @media (max-width: 768px) {
        font-size: 14px;
        word-wrap: keep-all;
        margin: 0;
    }
`;

const ResultText = styled.div`
    color: var(--orange);
    font-size: 32px;
    font-family: spoqaM;
    margin-bottom: 20px;
    display: flex;
    @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 10px;
    }
`;


const ContentsText = ({testResult, Question, ResultList, ResultDesc, ResultContents, ResultType}) => {

    return (
        <>
                { testResult ? 
                    (
                    testResult[0]?.error || testResult[0]?.status ?
                        <QuestionText> {Question} </QuestionText>
                        :
                    (
                        testResult.length <= 1 ? 
                            (<ResultBox>
                                <FontRegular> {ResultDesc} </FontRegular>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <ResultText style={{marginBottom: '0'}}> {ResultContents} </ResultText>
                                    {ResultType}
                                </div>
                            </ResultBox>)
                        :
                        (
                        <ResultBox>
                            <FontRegular> {ResultDesc} </FontRegular>
                            <ResultText>
                                {ResultContents}
                                <p style={{color: 'var(--primary)', fontSize: '22px'}}>{ResultType[1]}</p>
                            </ResultText>
                            <div></div>
                            <ResultGraph ResultList={ResultList} ResultType={ResultType[0]} />
                        </ResultBox>
                        )
                    )
                )
                :
                (<QuestionText> {Question} </QuestionText>) }
        </>
    );
};

export default ContentsText;