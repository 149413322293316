import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loading from 'components/atoms/Loading';
import HamburgerButton from 'components/atoms/HamburgerButton';
import Container from 'components/atoms/Container';
import PageTitle from 'components/atoms/PageTitle';
import ImageUploader from 'components/ImageUploader';
import ContentsText from 'components/atoms/ContentsText';
import SubmitButton from 'components/atoms/SubmitButton';
import ShareButtonContainer from 'components/ShareButtonContainer';
import RefreshButton from 'components/atoms/RefreshButton';
import FunfunContentsList from 'components/FunfunContentsList';

import usePostImage from 'hooks/usePostImage';
import useGetURL from 'hooks/useGetURL';

import { reset } from 'slices/ImageUploadSlice';
import { getTests } from 'slices/FunFunSlice';

const MyGoldenRatio = () => {

    // slice 에서 받아오는 props
    const { result, itemResult } = useSelector(state => state.funFun);
    const { testResult, loading, rejected } = useSelector(state => state.imageUpload);

    // customHook 에서 받아오는 props
    const {getRootProps, getInputProps, imageSrc, setImageSrc, isDragActive,
        _formData, onImageChange, decoded, selectedWrongFile} = usePostImage({testResult});
    const currentAPI = itemResult?.myGoldenRatio.api;
    const {testPostAPI, pageUrl}  = useGetURL({currentAPI});

    const dispatch = useDispatch();

    useEffect(() => {
        if(!result) {
           dispatch(getTests({ url:`https://flightbase.acryl.ai/deployment/service-api` })); 
        }
    }, []);

    // 페이지 렌더링시 testResult 를 초기화 
    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);

    const ResultContents = Math.floor((testResult?.funfun_result && testResult.text[6].total) * 100) + '점';

    const ResultList = testResult?.funfun_result && Object.entries(testResult?.funfun_result[0]);
    if (testResult?.funfun_result) {
        [ResultList[1], ResultList[3]] = [ResultList[3], ResultList[1]];
        [ResultList[2], ResultList[3]] = [ResultList[3], ResultList[2]];
    }

    return (
        <>
            <Container>
                <PageTitle testTitle={itemResult?.myGoldenRatio.name}
                        modelName={result?.[2].built_in_model_info_list[0].name}
                        pageName={'내 얼굴의 황금비율을 '}
                        modelDesc={result?.[2].built_in_model_info_list[0].description} />
                {/* 로딩 중이라면 로딩 컴포넌트를 출력 */}
                {
                    loading ? <Loading /> :
                    <>
                    <ImageUploader testResult={testResult} rejected={rejected} imageSrc={imageSrc} onImageChange={onImageChange}
                    getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} decoded={decoded}
                    selectedWrongFile={selectedWrongFile} />
                    <ContentsText testResult={testResult?.funfun_result} ResultList={ResultList}
                                    Question={'얼굴이 잘 보이는 정면 사진으로 확인 해보세요!'}
                                    ResultDesc={'AI가 분석한 당신의 황금 비율은...'} ResultContents={ResultContents} ResultType={['점', ' 입니다!']}/>
                    {/* 테스트 결과 값이 있을 때는 새로고침 버튼, 없을 때는 submit 버튼 출력 */}
                    {
                        testResult || rejected || selectedWrongFile ? 
                        <RefreshButton /> :
                        <SubmitButton formData={_formData} testPostAPI={testPostAPI}/>
                    }
                    </>
                }
                <ShareButtonContainer testTitle={itemResult?.myGoldenRatio.name} testUrl={pageUrl}/>
                <FunfunContentsList result={result} setImageSrc={setImageSrc}/>
            </Container>
        </>
    );
};

export default MyGoldenRatio;