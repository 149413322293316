import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import BuiltInModelInfo from 'components/atoms/BuiltInModelInfo';
import BuiltInModelMenu from 'components/BuiltInModelMenu';

import BackGroundImage from 'images/builtInBackGround.png';
import MobileBackGround from 'images/mobileBuiltInBackGround.png';

const Container = styled.div`
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media (max-width: 768px) {
        width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 90%;
    }
`;

const ContainerBackGround = styled.div`
    width: 100%;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center top;
    @media (max-width: 768px) {
        width: 100%;
        background-size: 100%;
        margin-top:-10px;
    }
`;

const Test = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        align-items: flex-end;
    }
`;

const BuiltInModel = () => {

    const { result } = useSelector(state => state.builtInModel);

    const portalURL = result?.portal_url;
    const acrylURL = result?.acryl_url;
    const tutorialURL = result?.tutorial_url;

    return (
        <>
            {
                window.innerWidth < 768 ?
                (
                <ContainerBackGround style={{backgroundImage: `url(${MobileBackGround})`}}>
                    <Container >
                        <BuiltInModelInfo portalURL={portalURL}/>
                            <Test>
                                <BuiltInModelMenu
                                tutorialURL={tutorialURL}
                                acrylURL={acrylURL}
                                />
                            </Test>
                        </Container>
                </ContainerBackGround>
                ) :
                (
                    <ContainerBackGround style={{backgroundImage: `url(${BackGroundImage})`}}>
                        <Container >
                            <BuiltInModelInfo portalURL={portalURL}/>
                            <Test>
                                <BuiltInModelMenu
                                tutorialURL={tutorialURL}
                                acrylURL={acrylURL}
                                />
                            </Test>
                        </Container>
                    </ContainerBackGround>
                )
            }
        </>
    );
};

export default BuiltInModel;