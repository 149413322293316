import React, { useEffect } from 'react';
import styled from 'styled-components';

import KakaoLogo from 'images/kakao.png';

const KakaoLogoBtn = styled.button`
    border: none;
    width: 50px;
    background-color: var(--blue100);
    cursor: pointer;
`;

const KakaoLogoImage = styled.img`
    width: 100%
`;

const KakaoShareBtn = ({testTitle, testUrl}) => {

    useEffect(() => {
        CreateKakaoButton();
    }, []);

    const CreateKakaoButton = () => {
      
        if (window.Kakao) {
            const Kakao = window.Kakao;

            if (!Kakao.isInitialized()) {
                Kakao.init(process.env.REACT_APP_KAKAO_KEY)
            }
            var testLink = testUrl.url;
            Kakao.Link.createDefaultButton({
                container: '#kakao_link_btn',
                objectType: 'feed',
                content: {
                    title: testTitle,
                    description: 'AI 테스트 해보기',
                    imageUrl: '',
                    link: {
                        mobileWebUrl: testLink,
                        webUrl: testLink
                    }
                },
                buttons: [
                  {
                    title: '웹으로 보기',
                    link: {
                      mobileWebUrl: testLink,
                      webUrl: testLink,
                    },
                  },
                  {
                    title: '앱으로 보기',
                    link: {
                      mobileWebUrl: testLink,
                      webUrl: testLink,
                    },
                  },
                ],
            });
        }
    }


    return (
            <KakaoLogoBtn id="kakao_link_btn">
                <KakaoLogoImage src={KakaoLogo} />
            </KakaoLogoBtn>
    );
};

export default KakaoShareBtn;