import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const usePostImage = ({testResult}) => {

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone();

    const [imageSrc, setImageSrc] = useState('');
    let [decoded, setDecoded] = useState([]);
    const [_formData, setFormData] = useState();
    const [selectedWrongFile, setSelectedWrongFile] = useState(false);
    const [resetImageSrc, setResetImageSrc] = useState(false);
    
    const onImageChange = (e) => {
        const image = e;
        let formData = new FormData();
        const fileReader = new FileReader();

        if (!e.type.includes('image', 0)) {
            setSelectedWrongFile(true);
            setImageSrc('');
            return;
        }

        fileReader.readAsDataURL(image);
        fileReader.onload = (e) => {
            setImageSrc(e.target.result);
        }
        formData.append('image', image);
        setFormData(formData);
    }

    useEffect(()=>{
        acceptedFiles.length > 0 && onImageChange(acceptedFiles[0])
    },[acceptedFiles])

    // useEffect(()=>{
    //     if(testResult?.image){
    //         let decoded =  '';
    //         decoded = `data:image/jpeg;base64,${testResult?.image[0].Image}`;
    //         setDecoded(decoded)
    //     }
    // },[testResult])

    useEffect(()=>{
        if (testResult?.image) {
            const resultImageBase64 = testResult?.image.reduce((resultImageArr, result) => {
                    for(let resultImageValue in result){
                        resultImageArr.push(result[resultImageValue]);                    
                    }
                    return resultImageArr;     
            },[]);

            if(resultImageBase64){
                let decodedArray = [];
                for (let i = 0; i < resultImageBase64.length; i++) {
                    decodedArray.push(`data:image/jpeg;base64,${resultImageBase64[i]}`)
                }
                setDecoded(decodedArray);
            }
        }
    },[testResult])

    return {
        getRootProps, 
        getInputProps, 
        imageSrc,
        setImageSrc, 
        isDragActive, 
        _formData, 
        decoded, 
        onImageChange,
        selectedWrongFile,
        resetImageSrc,
        setResetImageSrc
    };
};

export default usePostImage;