import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import GlobalStyles from './GlobalStyles';

import FunFun from 'pages/FunFun';
import BuiltInModel from 'pages/BuiltInModel';

import NumberOfHeads from 'pages/NumberOfHeads';
import HowMuchSimilar from 'pages/HowMuchSimilar';
// import WhoLooksLikeMe from 'pages/WhoLooksLikeMe';
// import WhichGroupMyDance from 'pages/WhichGroupMyDance';
import MyGoldenRatio from 'pages/MyGoldenRatio';

import NavBar from 'components/atoms/NavBar';
import HamburgerButton from 'components/atoms/HamburgerButton';
import Footer from 'components/atoms/Footer';

import { getURL } from 'slices/BuiltInModelSlice';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getURL());
  }, [])

  return (
    <>
    <GlobalStyles />
      <NavBar />
      <HamburgerButton />
      <Routes>
        <Route path='/' exact element={<FunFun />}/>
        <Route path='/built_in_model' element={<BuiltInModel />}  />
        <Route path='/service_detail/0' element={<NumberOfHeads />}/>
        <Route path='/service_detail/1' element={<HowMuchSimilar />}/>
        <Route path='/service_detail/2' element={<MyGoldenRatio />}/>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
