import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';

import { funFunSlice } from 'slices/FunFunSlice';
import { imageUploadSlice } from 'slices/ImageUploadSlice';
import { builtInModelSlice } from 'slices/BuiltInModelSlice';

// const logger = createLogger();

const store = configureStore({
    reducer: {
        funFun: funFunSlice.reducer,
        imageUpload: imageUploadSlice.reducer,
        builtInModel: builtInModelSlice.reducer
    },
    middleware: [...getDefaultMiddleware({ serializableCheck: false })],
    devTools: true,
});

export default store;