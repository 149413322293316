import React from 'react';
import styled from 'styled-components';

import Card from 'components/atoms/Card';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from "swiper";
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper.min.css';
// import "swiper/components/pagination/pagination.scss";
// import "swiper/components/navigation/navigation.scss";

import 'css/swiperStyle.scss';

const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: no-wrap;
    @media screen and (max-width: 767px) {
        
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {

    }
    @media screen and (min-width: 1200px) {

    }
`;

SwiperCore.use([Pagination]);

const CardListWrapper = ({ result, onClickCard }) => {

    const CardList = () => {
        return result?.map(data =>
                    <Card
                        key={data.id}
                        id={data.id}
                        testTitle={data.name}
                        onClick={() => onClickCard(data.id)} 
                    />
        );
    }

    // 모바일
    if (window.innerWidth < 768) {
        return (
            <CardWrapper>
                <Swiper
                    loop={true}
                    centeredSlides={true}
                    initialSlide={1}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                            slidesPerGroup: 1,
                            spaceBetween: 50,
                            centeredSlides: true,
                            initialSlide: 2
                        },
                        500: {
                            slidesPerView: 3,
                            slidesPerGroup: 1,
                            spaceBetween: 150,
                            centeredSlides: true,
                        },  
                        1024: {
                            slidesPerView: 3,
                            slidesPerGroup: 1,
                            spaceBetween: 10,
                            loop: false
                        }
                    }}
                    >
                    {
                        result?.map((data) =>{
                            return (
                                <SwiperSlide key={data.id}>
                                    <Card id={data.id} testTitle={data.name} onClick={() => onClickCard(data.id)} />
                                </SwiperSlide>
                            );
                        })
                    }
                </Swiper>
            </CardWrapper>
        );
    } else {
        // PC
        return (
            <CardWrapper>
                <CardList />
            </CardWrapper>
        );
    }
};

export default CardListWrapper;