import React from 'react';
import styled from 'styled-components';

const GraphContainer = styled.div`
    width: 320px;
    display: flex;
    @media (max-width: 768px) {
        width: 120px;
    }
`;

const Stick = styled.div`
    width: 90%;
    height: 24px;
    background-color: var(--blue102);
    border-radius: 10px;
    margin: 0 auto;
    @media (max-width: 768px) {
        height: 14px;
    }
`;

const PercentStick = styled.div`
    width: ${props => props.Percent + '%' || '10%'};
    height: 100%;
    background-color: var(--blue105);
    border-radius: 10px;
`;



const StickGraph = ({Percent}) => {

    return (
        <GraphContainer>
            <Stick>
                <PercentStick Percent={Percent}/>
            </Stick>
        </GraphContainer>
    );
};

export default StickGraph;