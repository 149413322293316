import React from 'react';
import styled from 'styled-components';

import NeedToReload from './atoms/NeedToReload';

import Upload from 'images/upload.png';

const UploadBox = styled.div`
    border: 2px dashed var(--primary);
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(45, 118, 248, 0.1);
    width: 400px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    margin: 50px 0 20px 0;
    :hover {
        border: 2px solid var(--primary);
        background-color: var(--blue102);
    }
    @media (max-width: 768px) {
        width: 280px;
        height: 180px;
        margin: 40px 0 20px 0;
    }
`;

const ImageUpload = styled.label`
    width: 400px;
    height: 300px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    @media (max-width: 768px) {
        width: 250px;
        height: 180px;
        font-size: 12px;
        word-break: keep-all;
    }
    img {
        width: 70px;
        margin-bottom: 30px;
        @media (max-width: 768px) {
            width: 40px;
        }
    }
`;

const ImagePreview = styled.img`
    height: 450px;
    margin-top: 50px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        height: 270px;
        margin-bottom: 0;
    }
`;

const ResultImage = styled.img`
    height: 450px;
    margin-top: 30px;

    @media (max-width: 768px) {
        height: 270px;
    }
`;

const ResultImageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px 0 20px 0;
    @media (max-width: 768px) {
        margin: 30px 0 20px 0;
    }
    img {
        margin: 0;
        height: 450px;
        object-fit: contain;

        @media (max-width: 768px) {
            height: 270px;
        }
    }
`;

const ImageUploader = ({getRootProps, getInputProps, isDragActive,
                        onImageChange, imageSrc, testResult,
                        rejected, decoded, selectedWrongFile}) => {

    const needToUpload = () => {
           return  imageSrc ?
                    <ImagePreview src={imageSrc} /> :
                    <>
                    <UploadBox>
                        <ImageUpload {...getRootProps(onImageChange)}>
                            <input type="file" accept="image/*" name="image" onChange={onImageChange} style={{display: 'none'}} {...getInputProps(onImageChange)} />
                            {
                                isDragActive? <ImageUpload>이곳에 드롭해 주세요 :)</ImageUpload> :
                                <ImageUpload>
                                    <img src={Upload} />
                                    <p>사진을 끌어오거나 눌러서 선택해 주세요</p>
                                </ImageUpload>
                            }
                        </ImageUpload>
                    </UploadBox>
                    <p style={{fontSize:'14px', fontFamily: 'spoqaR', color: 'var(--red)', marginTop: '20px'}}>*선택하시는 사진은 절대 저장되지 않습니다*</p>
                    </>
    }

    // testResult 값이 있다면? imageSrc or decoded 값을 img src 로 사용하거나
    // testResult 값이 있지만 text 배열의 길이가 1이고, 돌아오는 image 소스가 없다면
    // 적절하지 않은 사진이라는 결과이므로 NeedToReload 컴포넌트 출력, 
    // 아직 테스트 결과 값이 없다면 needToUpload 함수를 실행하여 return
    return (
        
        <>
            {
             selectedWrongFile ? <NeedToReload fileType={'사진'}/> : 
                (
                    testResult ? 
                        ((!testResult.image) || rejected ? <NeedToReload fileType={'사진'}/> : 
                        (decoded ?
                            <ResultImageWrapper>
                                {decoded.map((decoded) => <ResultImage src={decoded} key={'resultImage'}/>)}
                            </ResultImageWrapper> :
                            <ResultImage src={imageSrc} />)) :
                    needToUpload()
                )
            }
        </>
    );
};
export default ImageUploader;