// 본래 한 장짜리 업로더처럼 Hook 과 Component 를 분리했었지만
// ImageBox 의 Index 를 받아와 관리해야 하기 때문에
// 두장짜리 업로더는 Hoo과 Component 를 통합 시켰습니다.

import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import NeedToReload from './atoms/NeedToReload';

import Upload from 'images/upload.png';

import { useDropzone } from 'react-dropzone';

const BoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0 20px 0;
    @media (max-width: 768px) {
        margin: 40px 0 20px 0;
    }
`;

const UploadBox = styled.div`
    border: 2px dashed var(--primary);
    border-radius: 20px;
    box-shadow: 0 12px 24px rgba(45, 118, 248, 0.1);
    width: 300px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    margin: 0 20px;
    :hover {
        border: 2px solid var(--primary);
        background-color: var(--blue102);
    }
    @media (max-width: 768px) {
        width: 150px;
        height: 150px;
        margin: 0 10px;
    }
`;

const ImageUpload = styled.label`
    width: 300px;
    height: 250px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    @media (max-width: 768px) {
        width: 130px;
        height: 150px;
        font-size: 12px;
        word-break: keep-all;
    }
    img {
        width: 60px;
        margin-bottom: 30px;
        @media (max-width: 768px) {
            width: 38px;
        }
    }
`;

const ImagePreview = styled.img`
    margin: 0 20px;
    width: 350px;
    height: 350px;
    object-fit: contain;

    @media (max-width: 768px) {
        margin: 0 10px;
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
`;

const ResultImage = styled.img`
    margin: 0;
    width: 350px;
    height: 350px;
    object-fit: contain;

    @media (max-width: 768px) {
        margin: 0 10px;
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
`;

let currentFilePath = '';

const DoubleImageUploader = ({ testResult, setImageIndex, imagesSrc,
                            setImagesSrc, imagesData, setImagesData, rejected,
                            selectedWrongFile, setSelectedWrongFile}) => {

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone();

    // Result 로 넘어오는 인코딩 된 이미지를 디코딩하여 담기 위한 useState
    const [firstDecodedResult, setFirstDecodedResult] = useState(null);
    const [secondDecodedResult, setSecondDecodedResult] = useState(null);
    // const [selectedWrongFile, setSelectedWrongFile] = useState(false);
    const test = useRef();

    // 클릭시 imageIndex 업데이트
    const onUploaderClick = (idx) => {
        setImageIndex(idx);
    }

    // 사진 선택시 FileReader 를 통해 bese64 로 인코딩하고 인코딩된 값은 Src에, 파일 데이터는 Data에 담는 함수 
    const onImageChange = (image, index) => {
        if (!image.type.includes('image', 0)) {
            setSelectedWrongFile(true);
            setImagesSrc('');
            return;
        }

        const fileReader = new FileReader();
        fileReader.readAsDataURL(image);
        fileReader.onload = (e) => {
            setImagesSrc({...imagesSrc, [index]:e.target.result}) // 배열
        }
        setImagesData({...imagesData, [index]:image})
    }

    // useDropZone 의 acceptedFiles 에 담기는 데이터를 onChange 로 연결하는 useEffect
    useEffect(() => {

        // acceptedFiles 이 중간에 빈배열로 들어오는경우 return, acceptedFiles 두번 변경되는 현상 방지
        if (acceptedFiles.length === 0) return;
        if(currentFilePath === acceptedFiles[0]?.path) return;

        if(acceptedFiles.length > 0 && !currentFilePath) {
            onImageChange(acceptedFiles[0], 0);
            currentFilePath = acceptedFiles[0]?.path;

        // 드롭된 이미지가 하나 있다면?
        } else onImageChange(acceptedFiles[0], 1);
    }, [acceptedFiles])

    // testResult 에 이미지 값이 있다면? 해당 기능을 통해 값을 디코딩하여 변경시켜줌
    useEffect(() => {
        if(testResult?.image) {
            let firstDecoded = '';
            let secondDecoded = '';

            firstDecoded = `data:image/jpeg;base64,${testResult?.image[0].img_0[0]}`;
            setFirstDecodedResult(firstDecoded);
            
            secondDecoded = `data:image/jpeg;base64,${testResult?.image[0].img_0[1]}`;
            setSecondDecodedResult(secondDecoded);
        }
    },[testResult]);

    // testResult 가 있다면 이미지를 띄워주고, 업로드가 필요하다면 해당 컴포넌트를 띄워주기 위해
    // 함수로 컴포넌트를 return 시켜줌
    const needToUpload = () => {
        return (
            <>
                <BoxWrapper>
                    {
                        Object.keys(imagesSrc).map((image, imageIndex) => {

                            return !testResult && imagesSrc[image] ? 
                                <ImagePreview src={imagesSrc[image]} key={imageIndex}/> 
                                :
                                <UploadBox onClick={() => onUploaderClick(imageIndex)} key={imageIndex}>
                                    <input
                                        id={`image_input_${imageIndex}`}
                                        type='file'
                                        accept='image/*'
                                        name={`image_${imageIndex}`}
                                        style={{visibility: 'hidden'}}
                                        onChange={onImageChange}
                                        {...getInputProps(image)}
                                        />
                                    <ImageUpload
                                        {...getRootProps()}
                                        onClick={null}
                                        htmlFor={`image_input_${imageIndex}`}>
                                        {
                                            isDragActive? <p>이곳에 드롭해 주세요 :)</p>: 
                                                <>
                                                    <img src={Upload} />
                                                    <p>사진을 끌어오거나 눌러서 선택해 주세요</p>
                                                </>
                                        }
                                    </ImageUpload>
                                </UploadBox>
                        })
                    }     
                </BoxWrapper>
                <p style={{fontSize:'14px', fontFamily: 'spoqaR', color: 'var(--red)', marginTop: '20px'}}>*선택하시는 사진은 절대 저장되지 않습니다*</p>
            </>    
            );
    };

    // 디코딩된 result 값이 있다면 해당 소스를 띄워주고 아니라면 needToUpload 함수 출력
    return (
        <>      
            {
                selectedWrongFile ? <NeedToReload fileType={'사진'}/> : 
                    (
                    testResult?.text.length === 1 && !testResult.image) || rejected ? <NeedToReload fileType={'사진'}/>
                        :(
                            firstDecodedResult&&secondDecodedResult ? 
                                <BoxWrapper>
                                    <ResultImage src={firstDecodedResult} />
                                    <ResultImage src={secondDecodedResult} />
                                </BoxWrapper>
                            : needToUpload()
                    )
            }
        </>
    );

}

    

export default DoubleImageUploader;
