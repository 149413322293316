import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from "react-copy-to-clipboard";

import TwitterLogo from 'images/twitter.png';
import FacebookLogo from 'images/facebook.png';
import LinkLogo from 'images/link.jpg';

import KakaoShareBtn from './KakaoShareBtn';

const BtnContainer = styled.div`
    margin-top: 20px;
`;

const ShareButton = styled.button`
    border: none;
    width: 50px;
    cursor: pointer;
    background-color: var(--blue100);
    img {
        width: 100%;
    }
`;

const ShareText = styled.p`
    margin-top: 50px;
    @media (max-width: 768px) {
        font-size: 13px;
        margin-top: 30px;
    }
`;

const ShareButtonContainer = ({testTitle, testUrl}) => {

    const sendText = testTitle;
    const sendUrl = testUrl;

    const onClickTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?text=${sendText}&url=${sendUrl}`);
    }

    const onClickFacebook = () => {
        // window.open(`https://www.facebook.com/sharer/sharer.php?u=${sendUrl}`);
        // window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(sendUrl));
        let url = encodeURIComponent(sendUrl);
        let title = sendText;
        window.open(`http://www.facebook.com/sharer.php?u=${url}&t=${title}`);
    }
    
    // const onClickLinkCopy = (e) => {
    //     if (typeof navigator.share !== 'undefined') {
    //         navigator.share({
    //             title: sendText,
    //             text: '친구처럼 인공 지능 테스트 받아보기!',
    //             url: sendUrl,
    //         });
    //     } else{
    //         alert("공유하기가 지원되지 않는 환경 입니다.")
    //     }
    //     // navigator.clipboard.writeText(sendUrl);
    //     // alert('URL이 복사되었습니다 :)');
    // }

    const onClickLinkCopy = () => {
        document.execCommand('copy')
        alert('URL이 복사되었습니다!' + '\n' + '친구에게 공유해 주세요 :)');
    }

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://developers.kakao.com/sdk/js/kakao.js'
        script.async = true
    
        document.body.appendChild(script)
        return () => {
          document.body.removeChild(script)
        }
      }, [])

    return (
        <>
   
            <ShareText>친구의 테스트 결과도 궁금하다면?</ShareText>
            <BtnContainer>
                <ShareButton id="twitter_link_btn" onClick={onClickTwitter}>
                    <img src={TwitterLogo} />
                </ShareButton>
                <ShareButton id="facebook_link_btn" onClick={onClickFacebook}>
                    <img src={FacebookLogo} />
                </ShareButton>
                <CopyToClipboard text={sendUrl}>
                    <ShareButton onClick={onClickLinkCopy}>
                        <img src={LinkLogo} />
                    </ShareButton>
                </CopyToClipboard>
            </BtnContainer>
        </>
    );
};

export default ShareButtonContainer;