import React from 'react';
import styled from 'styled-components';

import AcrylLOGO from 'images/acrylLogo.png';

const FooterBG = styled.div`
    background-color: var(--white);
    height: 180px;
    width: 100%
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 80px;
        @media (max-width: 768px) {
            width: 50px;
            margin-bottom: 10px;
        }
    }
`;

const FooterContainer = styled.div`
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: space-between;
    p, a {
        font-size: 14px;
        font-family: MontserratM;
        color: var(--black);
    }
    @media (max-width: 768px) {
        width: 90%;
        flex-direction: column;
        margin: 0 auto;
        p, a {
            font-size: 10px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 90%;
    }
`;

const AcrylInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 48px;
    p, a {
        font-family: MontserratM;
    }
    @media (max-width: 768px) {
        margin-left: 0;
    }
`;

const AcrylTEL = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 25px;
    a {
        color: var(--black);
    }
    p, a {
        padding: 0 10px;
    }
    @media (max-width: 768px) {
        padding-right: 10px;
        p, a {
            padding: 0 7px;
        }
    }
`;

const BorderRight = styled.div`
    border-right: 1px solid var(--primary);
    height: 14px;
    margin-top: 1px;
    @media (max-width: 768px) {
        height: 10px;
    }
`;

const Footer = () => {
    return (
        <FooterBG>
            <FooterContainer>
                <img src={AcrylLOGO} /> 
                <AcrylInfoWrapper>
                    <p style={{marginBottom: '8px', wordBreak: 'keepAll'}}>7F/11F, Chungdam Venture Plaza. 704, Seolleung-ro, Gangnam-gu, Seoul, Korea (06069)</p>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <AcrylTEL>
                            <p style={{paddingLeft: '0px'}}>TEL</p>
                            <BorderRight />
                            <a href='tel:+82.2.557.4958'>+82.2.557.4958</a>
                        </AcrylTEL>
                        <AcrylTEL>
                            <p style={{paddingLeft: '0px'}}>FAX</p>
                            <BorderRight />
                            <p>+82.2.558.4958</p>
                        </AcrylTEL>
                        <AcrylTEL style={{paddingRight: '0px'}}>
                            <p style={{paddingLeft: '0px'}}>MAIL</p>
                            <BorderRight />
                            <a href='mailto:info@acryl.ai'>info@acryl.ai</a>
                        </AcrylTEL>
                    </div>
                    <p style={{marginTop: '20px', color: 'var(--gray03)'}}>© 2022 Acryl inc. All rights reserved.</p>   
                </AcrylInfoWrapper>
                
            </FooterContainer>
        </FooterBG>
    );
};

export default Footer;


