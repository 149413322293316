
import { useDispatch } from 'react-redux';

import { postImage } from 'slices/ImageUploadSlice';

import Button from 'components/atoms/Button';

const SubmitButton = ({formData, testPostAPI}) => {

    const dispatch = useDispatch();

    const onSubmit = (e) => {
        dispatch(postImage({formData, testPostAPI}));
        window.scrollTo({top: 0})
    }

    return (
        <>
            <Button onClick={onSubmit}>결과 확인하기</Button>
        </>
    );
    
}


export default SubmitButton;