import React from 'react';
import styled from 'styled-components';

import Button from 'components/atoms/Button';

const FlightbaseInfoBox = styled.div`
    width: 100%;
    height: 200px;
    displayL flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 70px 0 30px 30px;
    @media (max-width: 768px) {
        width: 90%;
        margin-top: 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin: 70px 0 30px 10px;
    }
    h1 {
        font-family: SpoqaB;
        margin: 10px 0;
        font-size: 32px;
        @media (max-width: 768px) {
            font-size: 26px;
        }
    }
    h2 {
        font-family: SpoqaM;
        margin: 10px 0;
        font-size: 22px;
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
`;

const BuiltInModelInfo = ({portalURL}) => {
    return (
        <>
            <FlightbaseInfoBox>
                <h2 style={{paddingTop: '35px'}}>통합 인공지능 플랫폼</h2>
                <h1 style={{marginBottom: '50px'}}>JONATHAN FLIGHTBASE</h1> 
                <Button>
                    <a href={portalURL} target='_blank' style={{color: 'var(--white)'}}>체험하러 가기</a>
                </Button>
            </FlightbaseInfoBox>
        </>
    );
};

export default BuiltInModelInfo;