import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const postImage = createAsyncThunk("POST_IMAGE", async (payload, { rejectWithValue })  => {
    let result = null;

    try {
        result = await axios.post(payload.testPostAPI, payload.formData);
    } catch (err) {
        result = rejectWithValue(err.response);
    }
    return result;
});

 
const initialState= {
    loading: false,
    testResult: null,
    resultImage: null,
    text: null,
    resultVideo: null,
    rejected: false
}

export const imageUploadSlice = createSlice({
    name: 'imageUpload',

    initialState: initialState,

    reducers: {
        reset(state) {
          Object.assign(state, initialState);
        }
    },

    extraReducers: {
        [postImage.pending]: (state) => {
            return {
                ...state,
                loading: true,
                rejected: false
            };
        },
        [postImage.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                testResult: payload.data,
                resultImage: payload.data.image && payload.data.image[0] ,
                text: payload.data.text,
                resultVideo: payload.data.video && payload.data.video.concat[0],
                rejected: false
            }
        },
        [postImage.rejected]: (state) => {
            return {
                ...state,
                loading: false,
                rejected: true
            } 
        }, 
    },    
});

export default imageUploadSlice.reducer;

export const { reset } = imageUploadSlice.actions;