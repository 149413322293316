import Button from 'components/atoms/Button';

const RefreshButton = () => {

    const onRefresh = (e) => {
        window.location.reload();
        window.scrollTo({top: 0})
    }

    return (
        <Button onClick={onRefresh}>다시 해보기</Button>
    );
};

export default RefreshButton;