import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import './css/font.scss';
import './css/color.scss';

const GlobalStyles = createGlobalStyle`

    ${reset}

    * {
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    
    a {
        text-decoration: none;
        color: var(--primary);
    }

    html {
        overflow-x: hidden;
    }

    body {
        font-family: SpoqaM;
        color: var(--primary);
        background-color: var(--blue100);
        padding: 0;
        margin: 0;
        overflow-x: hidden;
    }
`;

export default GlobalStyles;