import React from 'react';
import styled from 'styled-components';

import Container from './Container';

import LoadingIcon from 'images/loading.gif';

const LoadingSource = styled.img`
    width: 200px;
    @media (max-width: 768px) {
        width: 150px;
    }
`;

const LoadingText = styled.p`
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 20px;
    white-space: pre-wrap;
    line-height: 30px;
    margin: 0;
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const Loading = () => {
    return (
        <Container>
            <LoadingSource src={LoadingIcon} />
            <LoadingText>AI가 사진을 분석하고 있습니다</LoadingText>
        </Container>
    );
};

export default Loading;