import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NavBar from 'components/atoms/NavBar';
import Container from 'components/atoms/Container';
import HamburgerButton from 'components/atoms/HamburgerButton';
import Loading from 'components/atoms/Loading';
import PageTitle from 'components/atoms/PageTitle';
import DoubleImageUploader from 'components/DoubleImageUploader';
import ContentsText from 'components/atoms/ContentsText';
import SubmitButton from 'components/atoms/SubmitButton';
import ShareButtonContainer from 'components/ShareButtonContainer';
import RefreshButton from 'components/atoms/RefreshButton';
import FunfunContentsList from 'components/FunfunContentsList';
import Footer from 'components/atoms/Footer';

import { reset } from 'slices/ImageUploadSlice';
import { getTests } from 'slices/FunFunSlice';

import useGetURL from 'hooks/useGetURL';

const HowMuchSimilar = () => {

    // slice 에서 받아오는 props
    const { result, itemResult } = useSelector(state => state.funFun);
    const { testResult, loading, rejected } = useSelector(state => state.imageUpload);

    // customHook 에서 받아오는 props
    const currentAPI = itemResult?.howMuchSimilar.api;
    const {testPostAPI, pageUrl}  = useGetURL({currentAPI});

    const [imageIndex, setImageIndex] = useState([]);
    const [imagesSrc, setImagesSrc] = useState([null,null]);
    const [imagesData, setImagesData] = useState([]);
    const [_formData, setFormData] = useState([]);
    const [selectedWrongFile, setSelectedWrongFile] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!result) {
           dispatch(getTests({ url:`https://flightbase.acryl.ai/deployment/service-api` })); 
        }
    }, []);

    // 페이지 렌더링시 testResult 를 초기화 
    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);

    // DoubleImageUploader 에서 받아오는 imageData 를 formData 에 append
    useEffect(() => {
        if(imagesData.length === 0) return;
        let formData = new FormData();

        formData.append('image', imagesData[0]);
        formData.append('image', imagesData[1]);
        setFormData(formData);
    }, [imagesData]);

    // // 카카오 공유 기능을 위한 useEffect
    // useEffect(() => {
    //     const script = document.createElement('script')
    //     script.src = 'https://developers.kakao.com/sdk/js/kakao.js'
    //     script.async = true
    
    //     document.body.appendChild(script)
    //     return () => {
    //       document.body.removeChild(script)
    //     }
    //   }, []);

    const ResultContents = (Math.floor((testResult?.funfun_result && testResult.text[4].total) * 100) + '%');

    const ResultList = testResult?.funfun_result && Object.entries(testResult?.funfun_result && testResult?.funfun_result[0]);
    if (testResult?.funfun_result) {
        [ResultList[1], ResultList[4]] = [ResultList[4], ResultList[1]];
        [ResultList[3], ResultList[4]] = [ResultList[4], ResultList[3]];
    }

    return (
        <>
            <Container>
                <PageTitle testTitle={itemResult?.howMuchSimilar.name}
                        modelName={result?.[1].built_in_model_info_list[0].name}
                        pageName={'우리의 닮은 정도는 얼마인지 '}
                        modelDesc={result?.[1].built_in_model_info_list[0].description} />
                {/* 로딩 중이라면 로딩 컴포넌트를 출력 */}
                {
                    loading ? <Loading /> :
                    <>
                        <DoubleImageUploader testResult={testResult} rejected={rejected} setImageIndex={setImageIndex}
                        imagesSrc={imagesSrc} setImagesSrc={setImagesSrc} imagesData={imagesData} setImagesData={setImagesData}
                        selectedWrongFile={selectedWrongFile} setSelectedWrongFile={setSelectedWrongFile} />
                        <ContentsText testResult={testResult?.funfun_result} ResultList={ResultList}
                        Question={'얼굴이 잘 보이는 사진 두 장으로 확인 해보세요!'}
                        ResultDesc={'AI가 분석한 두 사람은...'} ResultContents={ResultContents} ResultType={['%', ' 닮았습니다!']}/>
                
                        {/* 테스트 결과 값이 있을 때는 새로고침 버튼, 없을 때는 submit 버튼 출력 */}
                        {
                            testResult || rejected || selectedWrongFile ? <RefreshButton /> :
                            <SubmitButton formData={_formData} testPostAPI={testPostAPI}/>
                        }
                    </>
                }
                <ShareButtonContainer testTitle={itemResult?.howMuchSimilar.name} testUrl={pageUrl} />
                <FunfunContentsList result={result} setImagesSrc={setImagesSrc} />
            </Container>
        </>
    );
};

export default HowMuchSimilar;