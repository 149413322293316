import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// import BuiltInModelCard from 'components/atoms/BuiltInModelCard';

import TutorialIcon from 'images/tutorialIcon.png';
import GuideIcon from 'images/guideIcon.png';
import HomepageIcon from 'images/homepageIcon.png';
import ArrowIcon from 'images/arrowIcon.png';
import NoCodeSrc from 'images/nocodeImage.png';

const ModelBoxWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 750px;
    margin-top: 20px;
    background-color: var(--white);
    border-radius: 50px 50px 0px 0px;
    box-shadow: -5px -5px 10px 0px rgba(45, 118, 248, 0.1);
    @media screen and (max-width: 768px) {
        align-items: flex-start;
        width: 90%;
        height: 700px;
        border-radius: 30px 0px 0px 0px;
        box-shadow: -5px -5px 10px 0px rgba(45, 118, 248, 0.1);
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        height: 600px;
    }
`;

const MenuList = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    @media screen and (max-width: 768px) {
        height: 300px;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        margin: 0px 0 0 30px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 100%;
        justify-content: space-evenly;
        margin-top: 20px;
    }
`;

const MenuWrapper = styled.a`
    width: 240px;
    height: 130px;
    cursor: pointer;
    border: 1px solid var(--gray02);
    border-radius: 25px;
    padding: 20px 40px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    img {
        width: 40px;
        height: 40px;
        margin-bottom: 25px;
    }
    p {
        width: 180px;
        font-family: SpoqaB;
        line-height: 24px;
        word-break: keep-all;
        color: var(--black);
        &.short {
            width: 120px;
            @media screen and (max-width: 768px) {
                width: 150px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 250px;
        height: 70px;
        margin: 10px 0;
        padding: 20px 10px 20px 20px;
        border-radius: 10px;
        img {
            height: 30px;
            width: 30px;
            margin: 0;
        }
        p {
            width: 150px;
            margin-left: 15px;
            font-size: 12px;
            font-family: SpoqaB;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 150px;
        height: 80px;
        padding: 30px 25px 30px 15px;
        justify-content: space-between;
        img {
            width: 28px;
            height: 28px;
            margin-bottom: 15px;
        }
        .arrow {
            width: 20px;
            height: 20px;
        }
        p {
            width: 150px;
            font-size: 14px;
        }
    }
`;

const Arrow = styled.img`
    margin-right: -20px;
    @media screen and (min-width: 768px) {
        display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        margin: 0;
        height: 8px;
    }
`;

const BuiltInModelExplainTextWrapper = styled.div`
    width: 600px;
    height: 200px;
    padding: 30px 0;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    color: var(--black);
    h1, h2, h3, p, div {
        width: 80%;
    }
    h1 {
        font-family: SpoqaB;
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 50px;   
    }
    h2 {
        font-family: SpoqaR;
        font-size: 30px;     
    }
    h3 {
        font-family: SpoqaB;
        font-size: 26px;
        margin-bottom: 10px;
    }
    p {
        line-height: 20px;
        font-family: SpoqaR;
        font-size: 18px;
        white-space: pre-line;
        line-height: 22px;
    }
    @media screen and (max-width: 768px) {
        margin: 0;
        margin-bottom: 30px;
        padding: 20px 0 20px 40px;
        h1, h2, h3, p, div {
            width: 130%;
        }
        h1 {
            font-family: SpoqaB;
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 50px;   
        }
        h2 {
            font-family: SpoqaR;
            font-size: 22px;     
        }
        h3 {
            font-family: SpoqaB;
            font-size: 18px;
            margin-bottom: 20px;
            margin-left: 0;
        }
        p {
            line-height: 20px;
            font-family: SpoqaR;
            font-size: 12px;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        h1, h2, h3, p, div {
            width: 100%;
        }
        h1 {
            font-family: SpoqaB;
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 35px;   
        }
        h2 {
            font-family: SpoqaR;
            font-size: 22px;     
        }
        h3 {
            font-family: SpoqaB;
            font-size: 18px;
            margin-bottom: 10px;
        }
        p {
            line-height: 20px;
            font-family: SpoqaR;
            font-size: 14px;
            white-space: pre-line;
            line-height: 22px;
        }
    }
`;

const NoCodeImage = styled.img`
    width: 500px;
    @media screen and (max-width: 768px) {
        display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 230px;
        height: 199px;
    }
`;

const BuiltInModelMenu = ({tutorialURL, acrylURL}) => {

    const BuiltInModelExplainText = '플랫폼에 탑재 되어있는 모델로 코드 작성 없이'
                                    + '\n' +
                                    '사용자가 데이터만 가지고 있다면, 환경 구성부터 학습,'
                                    + '\n' +
                                    '인공지능 서비스 실행 및 운영까지 모델 배포까지 전부'
                                    + '\n' +
                                    '플랫폼 웹서비스를 통해 체험할 수 있습니다.'

    return (
            <ModelBoxWrapper>
                <MenuList>
                    <MenuWrapper href={tutorialURL} target='_blank'>
                        <img src={TutorialIcon} />
                        <p className='short'>Quick Start 튜토리얼</p>
                        <Arrow src={ArrowIcon} className='arrow' />
                    </MenuWrapper>
                    <MenuWrapper href='\manual\Flightbase_User_Guide_220513.pdf' download target='_blank'>
                        <img src={GuideIcon} />
                            <p>Jonathan Flightbase 소개서 다운로드</p>
                        <Arrow src={ArrowIcon} className='arrow' />
                    </MenuWrapper>
                    <MenuWrapper href={acrylURL} target='_blank'>
                        <img src={HomepageIcon} />
                        <p className='short'>아크릴 홈페이지 바로 가기</p>
                        <Arrow src={ArrowIcon} className='arrow' />
                    </MenuWrapper>
                </MenuList>
                <div style={{width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <BuiltInModelExplainTextWrapper>
                    <h2>빌트인 모델을 통해서</h2>
                    <h1>코딩 없이 인공지능 개발 가능</h1>
                    <h3>빌트인 모델이란?</h3>   
                    <p>{BuiltInModelExplainText}</p>
                </BuiltInModelExplainTextWrapper>
                <NoCodeImage src={NoCodeSrc}/>  
                </div>                
            </ModelBoxWrapper>
    );
};

export default BuiltInModelMenu;