import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
 
export const getTests = createAsyncThunk("GET_TESTS", async (payload, { rejectWithValue }) => {
    let result = null;
    let url = payload.url;

    try {
        let apiUrl = url;
        result = await axios.get(apiUrl);

    } catch (err) {
        result = rejectWithValue(err.response);
    }
    return result;
});

 
export const funFunSlice = createSlice({
    name: 'funFun',
 
    initialState: {
        loading: false,
        result: null
    },
 
    reducers: {
        reset(state) {
          Object.assign(state);
        }
    },

    extraReducers: {
        [getTests.pending]: (state) => {
            return {
                ...state,
                loading: true
            }
        },
        [getTests.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                result: payload.data.result,
                itemResult: {
                    numberOfHeads: payload.data.result[0],
                    howMuchSimilar: payload.data.result[1],
                    myGoldenRatio: payload.data.result[2]
                }
            }
        },
        [getTests.rejected]: (state) => {
            return {
                ...state,
                loading: false
            }
        }
    }   
});
 
export default funFunSlice.reducer;