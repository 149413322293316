import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import JonathanLogo from 'images/jonathanLogo.png';
import FunfunLogo from 'images/funfunLogo.png';

const MenuContainer = styled.div`
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        width: 150px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media screen and (min-width: 1200px) {
        width: 1300px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
`;

const LinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;

const Menu = styled.li`
    list-style: none;
    @media screen and (max-width: 767px) {
        width: 210px;
        margin: 15px 0;
        text-align: right;
        .NavbarFunfunLogo {
            display: none;
        }
        .jonathanLogoBox {
            width: 210px;
            margin: 15px 0;
            position: relative;
            bottom: 8px;
            left: 24px;
        } 
        .jonathanLogo {
            padding: 5px 40px 5px 0px;
            width: 110px;
            height: 22px;
        }
    }
    img {
        width: 135px;
        height: 26px;
        margin: 0 25px;
    }
    a {
        height: 0;
    }
`;

const Titles = styled.p`
    text-decoration: none;
    font-family: spoqaM;
    font-size: ${props => props.fontSize || '16px'};
    padding: ${props => props.padding || '20px 23px'};
    height: 18px;
    text-align: ${props => props.textAlign || 'center'};
    word-break: keep-all;
    color: ${props => (props.current ? 'var(--blue105)' : 'var(--gray03)') || 'var(--gray03)'} ;
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding: 20px 20px
    }
`;

const BorderRight = styled.div`
    border-right: 2px solid var(--gray03);
    height: 18px;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const MenuList = ({fontSize, padding, textWidth, textAlign, onLinkClick}) => {
    const location = useLocation();
    const { result } = useSelector(state => state.builtInModel);

    const portalURL = result?.portal_url;

    return (
        <MenuContainer>
            <Menu>
                <Link to='/' className='NavbarFunfunLogo' style={{textDecoration: 'none'}}>
                    <img src={FunfunLogo}
                        style={{width: '60%', height: '60%'}} />
                </Link>
            </Menu>
            <LinkWrapper>
                <Menu>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Titles fontSize={fontSize} padding={padding} textWidth={textWidth} textAlign={textAlign}
                                current={location.pathname && !location.pathname.includes('built_in_model')}
                                onClick={onLinkClick}
                        >조나단의 AI 체험하기</Titles>
                    </Link>              
                </Menu>
                <BorderRight />
                <Menu>
                    <Link to='/built_in_model' style={{textDecoration: 'none'}}>
                        <Titles fontSize={fontSize} padding={padding} textWidth={textWidth} textAlign={textAlign}
                                current={location.pathname && location.pathname === '/built_in_model'}
                                onClick={onLinkClick}
                        >나만의 AI 체험하기</Titles>
                    </Link>                      
                </Menu>
                <BorderRight />
                <Menu>
                    <a className='jonathanLogoBox' href={portalURL} target='_blank'>
                        <img src={JonathanLogo}
                            className='jonathanLogo'
                            style={{marginLeft: '21px'}}/>
                    </a>
                </Menu>
            </LinkWrapper>
        </MenuContainer>
    );
};

export default MenuList;