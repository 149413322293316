import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loading from 'components/atoms/Loading';
import NavBar from 'components/atoms/NavBar';
import HamburgerButton from 'components/atoms/HamburgerButton';
import Container from 'components/atoms/Container';
import PageTitle from 'components/atoms/PageTitle';
import ImageUploader from 'components/ImageUploader';
import ContentsText from 'components/atoms/ContentsText';
import SubmitButton from 'components/atoms/SubmitButton';
import ShareButtonContainer from 'components/ShareButtonContainer';
import RefreshButton from 'components/atoms/RefreshButton';
import Footer from 'components/atoms/Footer';

import Button from 'components/atoms/Button';

import FunfunContentsList from 'components/FunfunContentsList';

import usePostImage from 'hooks/usePostImage';
import useGetURL from 'hooks/useGetURL';

import { reset } from 'slices/ImageUploadSlice';
import { getTests } from 'slices/FunFunSlice';

const NumberOfHeads = () => {

    // slice 에서 받아오는 props
    const { result, itemResult } = useSelector(state => state.funFun);
    const { testResult, loading, rejected } = useSelector(state => state.imageUpload);

    // customHook 에서 받아오는 props, 해당 페이지의 api URL은 currentAPI 에 담아 훅에 전달
    const {getRootProps, getInputProps, imageSrc, setImageSrc, isDragActive, _formData,
            decoded, onImageChange, selectedWrongFile} = usePostImage({testResult});
    const currentAPI = itemResult?.numberOfHeads.api;
    const {testPostAPI, pageUrl}  = useGetURL({currentAPI});

    const dispatch = useDispatch();

    useEffect(() => {
        if(!result) {
           dispatch(getTests({ url:`https://flightbase.acryl.ai/deployment/service-api` })); 
        }
    }, []);

    // 페이지 렌더링시 testResult 를 초기화 
    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, []);

    const ResultContents = testResult?.text[0].Heads;

    // 카카오 공유 기능을 위한 useEffect
    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://developers.kakao.com/sdk/js/kakao.js'
        script.async = true
    
        document.body.appendChild(script)
        return () => {
          document.body.removeChild(script)
        }
      }, [])

    //   useEffect(() => {
    //     const script = document.createElement('script')
    //     script.type = "text/javascript"
    //     script.src = "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-62e09ab0e8eff002"

    //     document.body.appendChild(script)
    //     return () => {
    //       document.body.removeChild(script)
    //     }
    //   }, [])

    return (
        <>
        <Container>
            <PageTitle testTitle={itemResult?.numberOfHeads.name}
                    modelName={result?.[0].built_in_model_info_list[0].name}
                    pageName={'나의 비율은 얼마인지 '}
                    modelDesc={result?.[0].built_in_model_info_list[0].description} />
            {/* 로딩 중이라면 로딩 컴포넌트를 출력 */}
            {
                loading ? <Loading /> :
                <>
                <ImageUploader testResult={testResult} rejected={rejected} imageSrc={imageSrc} decoded={decoded} onImageChange={onImageChange}
                getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} selectedWrongFile={selectedWrongFile} />
                <ContentsText testResult={testResult?.text} Question={'얼굴이 잘 보이는 전신 사진 한 장으로 확인 해보세요!'}
                                ResultDesc={'AI가 분석한 당신의 비율은...'} ResultContents={ResultContents} ResultType={'등신 입니다!'}/>                
                
                {/* 테스트 결과 값이 있을 때는 새로고침 버튼, 없을 때는 submit 버튼 출력 */}
                {
                    testResult || rejected || selectedWrongFile ? 
                    <RefreshButton /> :
                    <SubmitButton formData={_formData} testPostAPI={testPostAPI}/>
                }
                </>
            }
            {/* <div class="addthis_inline_share_toolbox_d54k"></div> */}
            <ShareButtonContainer testTitle={itemResult?.numberOfHeads.name} testUrl={pageUrl}/>
            <FunfunContentsList result={result} setImageSrc={setImageSrc}/>
        </Container>
        </>
    );
};
export default NumberOfHeads;