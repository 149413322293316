import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, Link } from 'react-router-dom';

import MenuList from './MenuList';

import HamburgerIcon from 'images/hamburger.png';
import CloseIcon from 'images/close.png';
import JonathanLogo from 'images/jonathanLogo.png';
import FunfunLogo from 'images/funfunLogo.png';

const MobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    padding: 15px 0;
    width: 100%;
    backdrop-filter: blur(2px);
    @media screen and (min-width: 767px) {
        display: none;
    }
`;

const FunfunLink = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;
    img {
        width: 150px;
    }
`;

const JonathanLink = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;
    img {
        width: 150px;
    }
`;

const Hamburger = styled.button`
    margin-right: 10px;
    width: 46px;
    cursor: pointer;
    border: none;
    z-index: 10000;
    background-color: var(--white);
    img {
        width: 100%;
        margin: 0 auto;
    }
`;

const Float = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SideMenuBox = styled.div`
    width: 100%;
    height: 180px;
    z-index: 100000;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    position: absolute;
    padding-right: 20px;
    @media screen and (min-width: 767px) {
        display: none;
    }
`;



const HamburgerButton = () => {

    const [sideMenu, setSideMenu] = useState(false);

    const onHamburgerClick = () => {
        if(sideMenu === false) {
            setSideMenu(true);
        } else {
            setSideMenu(false);
        }
    }
    const onMenuClick = () => {
        setSideMenu(false);
    }

    return (
        <>
        <MobileHeader>
            <FunfunLink onClick={onMenuClick}>
                <Link to='/'>
                    <img src={FunfunLogo} />
                </Link>
            </FunfunLink>
            <Hamburger onClick={onHamburgerClick}>
                {sideMenu? <img src={CloseIcon} /> : <img src={HamburgerIcon} />}
            </Hamburger>
        </MobileHeader>       
            {sideMenu &&
                (
                <Float>
                    <SideMenuBox>
                        <Float style={{marginTop: '-35px'}}>
                            <MenuList fontSize={'14px'}
                                    padding={'5px 40px 5px 0px'}
                                    textWidth={'100%'}
                                    textAlign={'right'}
                                    onLinkClick={onMenuClick} />
                        </Float>
                    </SideMenuBox>
                </Float> 

                )
            }
        </>
    );
};

export default HamburgerButton;